// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.registry-totals-container
  display: flex
  gap: $cs.m

  .registry-total-box
    background-color: $c-divider-subtle
    border-input()
    display: flex
    flex-direction: column
    padding: $cs.l
    margin-bottom: $cs.s
    width: 12rem

.progress-bar-message
  display: block

.progress-bar
  height: 2rem

.subscription-info
  & > div
    padding: $cs.l
    border-input()

  &:first-child > div
      background-color: $c-divider-subtle

  p
    font-size: $fs.l

  &:not(:first-child)
    & > div
      border-color: $c-active-blue
      box-shadow: 0 0 10px 0 white
      transform: scale(1)
      transition: all $ad.m

      &:hover
        box-shadow: 0 0 10px 0 lighten($c-active-blue, 50%)
        transform: scale(1.01)
