// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

.container
  width: 10rem
  height: 1.3rem
  position: relative
  overflow: hidden
  font-size: $fs.s

  .cycle
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0

    div
      height: 1.3rem
      display: flex
      justify-content: center
      opacity: 0

      &.visible
        opacity: 1

  .transitioned
    transition: top 1.4s ease-in-out

  .fade
    div
      transition: opacity 700ms linear

  .warning
    color: $tc-warning

  .icon
    margin-left: $cs.xxs
    color: $tc-subtle-gray

.align-left
  .cycle div
    justify-content: flex-start

.align-right
  .cycle div
    justify-content: flex-end

.align-center
  .cycle div
    justify-content: center
