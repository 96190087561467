// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// Note: Make sure to add any font `*.woff2` font files here to the preload list
// in `/lib/components/init.js` to the `fontsToPreload` array to have them
// properly preloaded and avoid flashes of unstyled text on initial page load.

// source-sans-pro-regular - latin_latin-ext
@font-face
  font-family: 'Source Sans Pro'
  font-style: normal
  font-weight: 400
  font-display: fallback
  src: url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-regular.eot') // IE9 Compat Modes.
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-regular.svg#SourceSansPro') format('svg') /* Legacy iOS */
// source-sans-pro-600 - latin_latin-ext
@font-face
  font-family: 'Source Sans Pro'
  font-style: normal
  font-weight: 600
  font-display: fallback
  src: url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-600.eot') // IE9 Compat Modes.
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
    url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
    url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-600.svg#SourceSansPro') format('svg') /* Legacy iOS */

// source-sans-pro-700 - latin_latin-ext
@font-face
  font-family: 'Source Sans Pro'
  font-style: normal
  font-weight: 700
  font-display: fallback
  src: url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-700.eot') // IE9 Compat Modes.
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
      url('../assets/fonts/source-sans-pro-v13-latin_latin-ext-700.svg#SourceSansPro') format('svg') /* Legacy iOS */

@font-face
  font-family: 'IBM Plex Mono'
  font-style: normal
  font-weight: 400
  font-display: fallback
  src: url('../assets/fonts/ibm-plex-mono-regular.eot') // IE9 Compat Modes.
  src: local('IBM Plex Mono'), local('IBMPlexMono'),
      url('../assets/fonts/ibm-plex-mono-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('../assets/fonts/ibm-plex-mono-regular.woff') format('woff'), /* Modern Browsers */
      url('../assets/fonts/ibm-plex-mono-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('../assets/fonts/ibm-plex-mono-regular.svg#IBMPlexMono') format('svg') /* Legacy iOS */

@font-face
  font-family: 'Material Icons'
  font-style: normal
  font-weight: 400
  font-display: block
  src: url('../assets/fonts/materialicons.eot') // IE9 Compat Modes.
  src: url('../assets/fonts/materialicons.woff2') format('woff2'),
      url('../assets/fonts/materialicons.woff') format('woff'),
      url('../assets/fonts/materialicons.ttf') format('truetype'),
      url('../assets/fonts/materialicons.svg#MaterialIcons-Regular') format('svg')

@font-face
  font-family: 'text-security-disc'
  src: url('../assets/fonts/text-security-disc.eot')
  src: url('../assets/fonts/text-security-disc.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/text-security-disc.woff2') format('woff2'),
      url('../assets/fonts/text-security-disc.woff') format('woff'),
      url('../assets/fonts/text-security-disc.ttf') format('truetype'),
      url('../assets/fonts/text-security-disc.svg#text-security') format('svg')
